:root {
    --primary-color: #f57919;
    --secondary-color: #08207c;
    --calendar-button-color: rgba(45, 179, 229, 0.1);
    --form-steps-bg: #969697;
    --form-step-label: white;
    --circle-selected-text: white;
    --circle-unselected-bg: #bcbcbd;
    --circle-unselected-color: #d1d1d1;
    --circle-unselected-text: #969697;
    --back-button-color: #565656;
}