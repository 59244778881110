* {
    font-family: sans-serif;;
}

html, body {
    min-height: 100%;
    height: 100%;
}

body {
    margin: 0;
    background-color: rgb(240, 240, 240);
}

#root {
    height: 100%;
    background-image: url('../../../public/BG_IMOC.png');
    background-size: 100% 100%;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* MD */
@media (min-width: 900px) {
    .margin-top-boolean {
        margin-top: 8px;
    }
    .send-request-button {
        width: 50%;
        border-radius: 30px!important;
    } 
    .pdf-button {
        width: 30%;
        border-radius: 30px!important;
    } 
    .custom-form {
        width: 80%;
    }
    .date-picker-style {
        position: relative;
    }
    .date-picker-style:before {
        content: '';
        position: absolute;
        right: 0%;
        top:20%;
        height: 60%;
        border-right: 1px solid grey;
      }
}
/* XS */
@media (max-width: 900px) {
    .padding-none-mobile {
        padding: 0!important;
    }
    .send-request-button {
        width: 100%;
        border-radius: 30px!important;
    } 
    .pdf-button {
        width: 100%;
        border-radius: 30px!important;
    }
    .form-steps-bg, .resumen-circle {
        display: none;
    }
    .custom-form {
        width: 90%;
    }
}

/* XXS */
@media (max-width: 460px) {
    .date-picker-style>div>div>div>div {
        width: 100%!important;
    }
    .date-picker-style>div>div>div>div>div {
        width: 100%!important;
    }
    .send-request-button {
        width: 100%;
        border-radius: 30px!important;
    } 
    .pdf-button {
        width: 100%;
        border-radius: 30px!important;
    } 
    .form-step-slider span.MuiSlider-markLabel {
        width: 20%;
        white-space: normal;
    }
    .form-steps-bg, .resumen-circle {
        display: none;
    }
    .custom-form {
        width: 90%;
    }
}

.m-auto {
    margin: auto;
}

.resumen-circle {
    margin-top: 25px!important;
}

.bottom-right-text {
    font-size: 30px;
    font-weight: bold;
    background-image: linear-gradient(90deg, #ff0077, #F57919);
    background-clip: text;
    color: transparent;
    margin: auto 3% 3% 0;
    align-self: flex-end;
}

.circle {
    border-radius: 100%; 
    width: 40px; 
    line-height: 40px; 
    text-align: center; 
    margin: auto;
    background-color: var(--circle-unselected-color); 
    color: var(--circle-unselected-text);
    font-weight: bold;
    font-size: 25px;
}

.circle-selected {
    background-color: var(--secondary-color); 
    color: var(--circle-selected-text);
}

.circle-2 {
    border-radius: 100%; 
    width: 55px; 
    height: 55px;
    display: flex;
    margin: 0 auto 15px auto;
    background-color: var(--circle-unselected-bg);
}

.form-page {
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.form-navbar {
    margin: 1px 0 40px 0!important;
    width: auto!important;
}

.form-title {
    font-size: 40px;
    margin: 0;
}

.form-buttons {
    margin-top: 15px!important;
}

.phone-field input {
    width: 100%!important;
    padding: 16.5px 14px 16.5px 48px!important;
    line-height: 1.4375em!important;
    border-radius: 30px!important;
    background-color: rgb(245, 245, 245)!important;
}
.phone-field input:focus {
    outline: 0!important;
}

.phone-field .special-label {
    background-color: transparent!important;
    margin-bottom: 30px!important;
    top: -15px !important;
    left: 12px !important;;
}

.confirmation-control {
    display: flex!important;
}

.confirmation-control * {
    margin: auto!important;
    margin-top: 30px!important;
}

.confirmation-control h3 {
    color: var(--secondary-color);
}

.date-picker-style .Mui-selected {
    background-color: var(--secondary-color)!important;
}

.date-picker-style button {
    border-radius: 30%!important;
    background-color: var(--calendar-button-color);
}

.date-picker-style button.Mui-disabled {
    background-color: #d1d1d1;
    color: rgb(130, 130, 130);
}

.form-button-width {
    width: 100%;
    border-radius: 30px!important;
    padding: 10px!important;
}
.form-button-bg:not(.Mui-disabled) {
    background-image: linear-gradient(to right, #00b0f6 16%, #89deff 142%)!important;
}
.form-border-bg {
    border: 1px solid white!important;
    color: var(--back-button-color)!important;
}

.step-title {
    margin: 0 0 30px 0;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
    color: var(--secondary-color);
}

.confirmation-subtitle {
    margin: 0;
    margin-top: 30px;
    padding-bottom: 10px;
    color: #565656;
}

.margin-h3-resumen {
    margin-top: 5px;
    margin-bottom: 5px;
}

.font-weight-normal {
    font-weight: 400;
}

hr{
    border:none;
    border-top: 1px solid var(--secondary-color);
    color:#fff;
    background-color:#fff;
    height:1px;
}

.border-radius-30 div {
    border-radius: 30px;
    background-color: rgb(245, 245, 245);
}

.border-radius-30-fieldset fieldset {
    border-radius: 30px!important;
}
.border-radius-30-fieldset div {
    border-radius: 30px!important;
    background-color: rgb(245, 245, 245)!important;
}

.margin-bottom-text-field {
    margin-bottom: 20px!important;
}

.margin-text-field {
    margin-top: 20px!important;
    margin-bottom: 20px!important;
}

.form-title-grid {
    display: flex;
    flex-direction: column!important;
    justify-content: center;
}

.form-image {
    height: 90%;
    width: 20%;
    margin-left: 10px;
}

.custom-form {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    background-color: #fff;
    margin: 0 auto 50px auto;
    border-radius: 20px;
    border: 1px solid rgb(69, 68, 68);
}

.form-content {
    padding: 30px 40px;
    width: auto !important;
    flex-basis: 78% !important;
    -webkit-flex-basis: 78% !important
}

.form-steps {
    padding: 50px 0px;
}

.margin-0 {
    margin: 0!important
}

.step-label {
    text-align: center;
    color: var(--form-step-label);
    width: 15vw;
}
.step-label:not(:last-of-type) {
    margin-bottom: 70px;
}

.form-steps-bg {
    background-color: var(--form-steps-bg);
    border-radius: 18px 0 0 18px;
    flex-basis: 10% !important;
    -webkit-flex-basis: 10% !important;
    max-width: 60% !important;
}

.date-label {
    font-size: 1rem;
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    font-weight: 400;
    color: rgba(0, 0, 0);
}

.form-step-slider {
    padding-right: 30px!important;
    padding-left: 30px!important;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column!important;
    justify-content: center;
}

.form-step-slider span {
    margin-top: auto;
    margin-bottom: auto;
}

.styled-toggle-buton {
    padding-left: 15px;
    box-sizing: border-box!important;
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-flex-wrap: wrap!important;
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important;
    width: 100%!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
    color: standard!important;
}
@media (min-width: 600px) {
    .styled-toggle-buton button {
        -webkit-flex-basis: 100%!important;
        -ms-flex-preferred-size: 100%!important;
        flex-basis: 100%!important;
        -webkit-box-flex: 0!important;
        -webkit-flex-grow: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important;
        max-width: 100%!important;
    }
}
@media (min-width: 900px) {
    .styled-toggle-buton button {
        -webkit-flex-basis: 50%!important;
        -ms-flex-preferred-size: 50%!important;
        flex-basis: 50%!important;
        -webkit-box-flex: 0!important;
        -webkit-flex-grow: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important;
        max-width: 47%!important;
    }
}

.styled-toggle-buton button {
    color: black;
    width: 100%;
    box-sizing: border-box!important;
    margin: 0!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0!important;
    -webkit-flex-grow: 0!important;
    -ms-flex-positive: 0!important;
    flex-grow: 0;
    max-width: 100%;
    display: block!important;
    margin-left: 8px!important;
    margin-top: 8px!important;
}

.styled-toggle-buton button:hover {
    background-color: rgba(103, 103, 103, 0.089);
}

.date-picker-style>div>div {
    min-width: 0!important;
}

.container-grid-max-width{
    max-width: 1600px !important;
}

.time-label {
    font-size: 1rem;
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    font-weight: 400;
    color: #565656;
    text-align: center;
}

.return-button-container{
    display: flex;
}

.return-button{
    justify-content: center;
    align-items: center;
}